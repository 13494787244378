import { createContext, Dispatch, FunctionComponent, useReducer } from "react";
import { Action, initialState, TypingState, reducer } from "./state";

export const typingContext = createContext<
  [TypingState, Dispatch<Action<any>>]
>([initialState, () => {}]);

export const TypingProvider: FunctionComponent = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <typingContext.Provider value={[state, dispatch]}>
      {children}
    </typingContext.Provider>
  );
};

import React, { useEffect } from "react";
import CustomCombobox from "../components/Common/CustomCombobox";
import cx from "classnames";
import { useState } from "react";
import client from "../graphql";
import get from "lodash/get";
import { GET_SETTING } from "../graphql/query";
import { useMutation } from "@apollo/client";
import { UPDATE_SETTING } from "../graphql/mutation";
import { toast } from "react-toastify";

function SettingPage() {
  const [mode, setMode] = useState("random");
  const [selected, setSelected] = useState({ id: "", title: "" });
  const [updateSetting, { data, loading, error }] = useMutation(UPDATE_SETTING);

  useEffect(() => {
    client
      .query({
        query: GET_SETTING,
      })
      .then((result) => {
        const mode = get(result, "data.getSetting.mode");
        const text = get(result, "data.getSetting.text");
        setMode(mode);
        setSelected(text);
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }, []);

  const handleUpdate = () => {
    updateSetting({
      variables: {
        textId: selected.id,
        mode,
      },
    })
      .then((result) => {
        console.log(result);
        toast.success("Updated setting successfully!");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error. Please try again!");
      });
  };

  return (
    <div className="w-full h-full flex justify-center py-20">
      <div className="lg:w-1/2 sm:w-3/4">
        <div className="mb-2">
          <div className="mb-2">Setting</div>
          <div className="border p-4 grid grid-cols-2 gap-4 h-48">
            <div className="w-full">
              <div>
                <div className="font-bold mb-4">Mode</div>
                <div className="w-full">
                  <select
                    value={mode}
                    onChange={(e) => {
                      setMode(e.target.value);
                    }}
                    className="outline-none focus:ring-0 border py-2 pl-3 pr-3 mr-2 w-full max-w-xs"
                  >
                    <option value="" disabled>
                      Select mode
                    </option>
                    <option value={"random"}>Random</option>
                    <option value={"fixed"}>Fixed</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="w-full">
              <div>
                <div className="font-bold mb-4">Select text</div>
                <CustomCombobox selected={selected} setSelected={setSelected} />
              </div>
            </div>
          </div>

          <div className="w-full mt-4">
            <div className="text-left">
              <button
                onClick={handleUpdate}
                className={cx("btn btn-outline btn-sm", { loading: loading })}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SettingPage;

import React, { useState } from "react";
import { Routes, Route, Link } from "react-router-dom";
import MenuButton from "./components/Common/MenuButton";
import HomePage from "./pages/HomePage";
import SettingPage from "./pages/Setting";
import CreateYourTextPage from "./pages/YourText/Create";
import EditYourTextPage from "./pages/YourText/Edit";
import ListYourTextPage from "./pages/YourText/List";
import { AppProvider } from "./states";
import { TypingProvider } from "./states/typing-state/context";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

function App() {
  return (
    <AppProvider>
      <TypingProvider>
        <div>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/setting" element={<SettingPage />} />
            <Route path="/your-text" element={<ListYourTextPage />} />
            <Route path="/your-text/create" element={<CreateYourTextPage />} />
            <Route path="/your-text/:id" element={<EditYourTextPage />} />
          </Routes>
          <MenuButton />
          <ToastContainer
            position="bottom-right"
            autoClose={3000}
            hideProgressBar={true}
          />
        </div>
      </TypingProvider>
    </AppProvider>
  );
}

export default App;

import React, { Fragment, useState, useCallback } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { HiSelector } from "react-icons/hi";
import client from "../../graphql";
import { GET_ALL_TEXT } from "../../graphql/query";
import debounce from "lodash/debounce";
import get from "lodash/get";

export default function CustomCombobox({ selected, setSelected }: any) {
  const [query, setQuery] = useState("");
  const [texts, setTexts] = useState([]);

  const debounceFetchAllText = useCallback(
    debounce((nextValue) => fetchAllText(nextValue), 300),
    []
  );

  const fetchAllText = (text: string) => {
    client
      .query({
        query: GET_ALL_TEXT,
        variables: {
          options: { search: text, limit: 20 },
        },
      })
      .then((result) => {
        console.log(result);
        const texts = get(result, "data.getAllText.data") || [];
        setTexts(texts);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const searchText = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    debounceFetchAllText(value);
    setQuery(value);
  };

  return (
    <div className="w-full ">
      <Combobox value={selected} onChange={setSelected}>
        <div className="relative mt-1">
          <div className="relative w-full cursor-default overflow-hidden bg-white text-left border focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
            <Combobox.Input
              value={query}
              className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
              displayValue={(text: any) => text.title}
              onChange={searchText}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <HiSelector
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery("")}
          >
            <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {texts.length === 0 && query !== "" ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                texts.map((text: any) => (
                  <Combobox.Option
                    key={text.id}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? "bg-teal-600 text-white" : "text-gray-900"
                      }`
                    }
                    value={text}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {text.title}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? "text-white" : "text-teal-600"
                            }`}
                          >
                            <AiOutlineCheckCircle
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
}

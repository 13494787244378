import React, { useEffect } from "react";
import { useTypingState } from "../../states/typing-state/useTypingState";
import { formattedSecond } from "../../utils/common";

function Timer() {
  const { state, stopTimer } = useTypingState();

  return (
    <div className="bg-black text-white text-2xl font-bold text-center fixed bottom-6 right-6  w-24 py-2">
      {formattedSecond(state.seconds)}
    </div>
  );
}

export default Timer;

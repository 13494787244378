import React from "react";
import { AiOutlineClose } from "react-icons/ai";

export default function Drawer({ children, isOpen, setIsOpen }: any) {
  return (
    <main
      className={
        " fixed overflow-hidden z-10 bg-gray-900 bg-opacity-25 inset-0 transform ease-in-out " +
        (isOpen
          ? "  opacity-100  translate-x-0  "
          : "  delay-300  -translate-x-full  ")
      }
    >
      <section
        className={
          " w-screen max-w-xs left-0 absolute bg-white h-full shadow-xl duration-200 ease-in-out transform " +
          (isOpen ? " translate-x-0 " : " -translate-x-full ")
        }
      >
        <article className="relative w-screen max-w-xs pb-10 flex flex-col space-y-6 overflow-y-auto h-full">
          <div className="flex justify-end items-end p-4">
            <button
              onClick={() => {
                setIsOpen(false);
              }}
              className="border rounded p-1"
            >
              <AiOutlineClose className="text-xl " />
            </button>
          </div>
          {children}
        </article>
      </section>
      <section
        className=" w-screen h-full "
        onClick={() => {
          setIsOpen(false);
        }}
      ></section>
    </main>
  );
}

import { addSeconds, format } from "date-fns";
export function hasWhiteSpace(s: string) {
  return s.indexOf(" ") >= 0;
}

export function hasEnter(s: string) {
  return s.indexOf("\n") >= 0;
}

export function formattedSecond(seconds: number) {
  if (!seconds) return "00:00";
  var helperDate = addSeconds(new Date(0), seconds);
  // if (seconds < 0) return '00:00'

  return format(helperDate, "mm:ss");
}

export function countCharExact(string1: string, string2: string) {
  let length =
    string1.length < string2.length ? string1.length : string2.length;
  let countCorrect = 0;
  let countWrong = 0;
  for (let i = 0; i < length; i++) {
    if (string1.charAt(i) === string2.charAt(i)) countCorrect++;
    else countWrong++;
  }

  return {
    countWrong,
    countCorrect,
  };
}

export function countWordExact(string1: string, string2: string) {
  string1 = standardizeString(string1);
  string2 = standardizeString(string2);
  const arr1 = string1.split(" ");
  const arr2 = string2.split(" ");

  let length = arr1.length < arr2.length ? arr1.length : arr2.length;
  let countCorrect = 0;
  let countWrong = 0;
  for (let i = 0; i < length; i++) {
    if ((arr1[i] = arr2[i])) countCorrect++;
    else countWrong++;
  }

  return {
    countWrong,
    countCorrect,
  };
}

export function standardizeString(str: string) {
  str = str.replace(/ {2}/, " ");
  str = str.trim();

  return str;
}

export const calculateResult = (text: string[], historyInput: string[]) => {
  const length = historyInput.length;

  let countCharCorrect = 0;
  let countCharWrong = 0;
  let countWordCorrect = 0;
  let countWordWrong = 0;

  for (let i = 0; i < length; i++) {
    const r1 = countCharExact(text[i], historyInput[i]);
    const r2 = countWordExact(text[i], historyInput[i]);

    countCharCorrect += r1.countCorrect;
    countCharWrong += r1.countWrong;

    countWordCorrect += r2.countCorrect;
    countWordWrong += r2.countWrong;
  }

  const keystrokes = countCharCorrect + countCharWrong;

  return {
    accuracy: ((countCharCorrect / keystrokes) * 100).toFixed(0),
    keystrokes: keystrokes,
    correctKeystrokes: countCharCorrect,
    wrongKeystrokes: countCharWrong,
    correctWords: countWordCorrect,
    wrongWords: countWordWrong,
  };
};

import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { GET_ALL_TEXT } from "../../graphql/query";
import LoadingSpinner from "./LoadingSpinner";
import get from "lodash/get";
import client from "../../graphql";
import { DELETE_MANY_TEXT, DELETE_ONE_TEXT } from "../../graphql/mutation";
import { Link } from "react-router-dom";
import { TiArrowUpThick } from "react-icons/ti";
import { format } from "date-fns";

function ListYourTextPage() {
  const [pagination, setPagination] = useState({ page: 1, rowsPerPage: 10 });
  const { loading, error, data, refetch } = useQuery(GET_ALL_TEXT, {
    variables: {
      options: {
        limit: pagination.rowsPerPage,
        page: pagination.page,
      },
    },
  });
  const [records, setRecords] = useState([]);
  const [rows, setRows] = useState([]);
  const [toggleCleared, setToggleCleared] = React.useState(false);
  const columns: TableColumn<any>[] = [
    {
      name: "ID",
      grow: 0,
      selector: (row: any, index: number | undefined) => (index || 0) + 1,
    },
    {
      name: "Title",
      grow: 1,
      wrap: true,
      selector: (row: any) => row.title,
    },
    {
      name: "Length",
      grow: 0,
      selector: (row: any) => row.sentences.length,
    },
    {
      name: "Created Date",
      maxWidth: "150px",
      selector: (row: any) => row.created_at,
      format: (row: any) =>
        `${format(new Date(row.created_at), "HH:mm dd-MM-yyyy")}`,
    },
    {
      name: "Action",
      button: true,
      cell: (row: any) => {
        return (
          <div className="flex gap-1">
            <Link
              to={"/your-text/" + row.id}
              className="modal-button text-green-600 border-green-600 border rounded p-1 hover:text-white hover:bg-green-600 transition duration-200"
            >
              Edit
            </Link>

            <label
              htmlFor={"list-delete-modal" + row.id}
              className="text-red-600 border-red-600 cursor-pointer border rounded p-1 hover:text-white hover:bg-red-600 transition duration-200"
            >
              Delete
            </label>
            <div>
              <input
                type="checkbox"
                id={"list-delete-modal" + row.id}
                className="modal-toggle"
              />
              <div className="modal whitespace-normal overflow-auto text-inheritl">
                <div className="modal-box">
                  <p className="font-bold text-xl text-error">Warning!</p>
                  <p className="pt-4 text-lg">
                    Do you really want to delete it?
                  </p>

                  <div className="modal-action">
                    <label
                      htmlFor={"list-delete-modal" + row.id}
                      className="btn btn-sm btn-outline btn-error"
                      onClick={() => {
                        console.log(row.id);
                        client
                          .mutate({
                            mutation: DELETE_ONE_TEXT,
                            variables: {
                              id: row.id,
                            },
                          })
                          .then((result) => {
                            console.log(result);
                          })
                          .catch((err) => {
                            throw err;
                          })
                          .finally(() => {
                            refetch({
                              options: {
                                limit: pagination.rowsPerPage,
                                page: pagination.page,
                              },
                            });
                          });
                      }}
                    >
                      Yes
                    </label>
                    <label
                      htmlFor={"list-delete-modal" + row.id}
                      className="btn btn-outline btn-sm"
                    >
                      Cancel
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  const handlePageChange = (page: number) => {
    refetch({
      options: {
        limit: pagination.rowsPerPage,
        page: page,
      },
    });
    setPagination({ ...pagination, page });
  };

  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    refetch({
      options: {
        limit: rowsPerPage,
        page: pagination.page,
      },
    });
    setPagination({ ...pagination, rowsPerPage });
  };

  const handleChange = (rows: any) => {
    setRows(rows.selectedRows);
  };

  const handleDeleteMany = () => {
    const ids = rows.map((row: any) => {
      return row.id;
    });

    client
      .mutate({
        mutation: DELETE_MANY_TEXT,
        variables: {
          ids: ids,
        },
      })
      .then((result) => {
        console.log(result);
        setToggleCleared(!toggleCleared);
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => {
        refetch({
          options: {
            limit: pagination.rowsPerPage,
            page: pagination.page,
          },
        });
      });
  };

  useEffect(() => {
    setRecords(get(data, "getAllText.data"));
  }, [data]);

  return (
    <div className="w-full h-full flex justify-center py-20">
      <div className="lg:w-1/2 sm:w-3/4">
        <div className="mb-2">
          <div className="mb-2">Your Text</div>
          <div className="border rounded">
            <DataTable
              title
              progressPending={loading}
              responsive
              progressComponent={<LoadingSpinner />}
              paginationTotalRows={get(data, "getAllText.pagination.total")}
              theme="default"
              columns={columns}
              pagination
              paginationServer
              data={records}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              selectableRows
              onSelectedRowsChange={handleChange}
              clearSelectedRows={toggleCleared}
              actions={
                <div>
                  <Link
                    to="/your-text/create"
                    className="btn btn-sm btn-outline btn-success"
                  >
                    Create a text
                  </Link>
                </div>
              }
              contextActions={
                <div>
                  <label
                    htmlFor="list-text-delete-all-modal"
                    className="btn btn-sm btn-error btn-outline"
                  >
                    Delete All
                  </label>
                </div>
              }
            />

            <input
              type="checkbox"
              id="list-text-delete-all-modal"
              className="modal-toggle"
            />
            <div className="modal">
              <div className="modal-box">
                <h3 className="font-bold text-lg text-error">Warning!</h3>
                <p className="py-4">
                  Do you really want to delete those things?
                </p>
                <div className="modal-action">
                  <label
                    htmlFor="list-text-delete-all-modal"
                    className="btn btn-error btn-outline btn-sm"
                    onClick={handleDeleteMany}
                  >
                    Yes
                  </label>
                  <label
                    htmlFor="list-text-delete-all-modal"
                    className="btn btn-outline btn-sm"
                  >
                    Cancel
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListYourTextPage;

import { useContext } from "react";
import { MdNavigateNext } from "react-icons/md";
import { calculateResult } from "../../utils/common";
import { typingContext } from "./context";
import { ACTIONS } from "./state";

export const useTypingState = () => {
  const [state, dispatch] = useContext(typingContext);

  const onEnterPressed = () => {
    const value = state.input;
    const text = [...state.text];

    if (text.length === 0) return;

    const historyInput = [...state.historyInput];
    historyInput.push(value);

    const result: any = calculateResult(text, historyInput);

    if (state.pos === state.text.length - 1) {
      stopTimer();
      result.wpm = Number(
        (result.correctKeystrokes * 60) / (5 * state.seconds)
      ).toFixed(0);

      dispatch({
        type: ACTIONS.ON_ENTER,
        payload: {
          text: text,
          pos: state.pos,
          input: "",
          historyInput: historyInput,
          result,
        },
      });

      dispatch({
        type: ACTIONS.ON_COMPLETE_TYPING,
        payload: {
          result,
        },
      });
    }

    return dispatch({
      type: ACTIONS.ON_ENTER,
      payload: {
        text: text,
        pos: state.pos + 1,
        input: "",
        historyInput: historyInput,
        result,
      },
    });
  };

  const startTimer = () => {
    const timerId = setInterval(timer, 1000);
    dispatch({ type: ACTIONS.SET_TIMER, payload: timerId });
  };

  const timer = () => {
    dispatch({ type: ACTIONS.TICK });
  };

  const onInput = (value: string) => {
    if (!state.timerId) {
      startTimer();
    }

    // If user press larger than text, then doesn't receive anything
    if (value.length > state.text[state.pos].length) {
      return;
    }

    dispatch({
      type: ACTIONS.ON_INPUT,
      payload: value,
    });
  };

  const initText = (text: any) => {
    dispatch({
      type: ACTIONS.INIT_TYPING,
      payload: {
        text,
      },
    });
  };

  const stopTimer = () => {
    clearInterval(state.timerId);
    dispatch({ type: ACTIONS.SET_TIMER, payload: undefined });
  };

  return {
    state,
    onInput,
    onEnterPressed,
    stopTimer,
    initText,
  };
};

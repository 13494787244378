import { gql } from "@apollo/client";

export const GET_TEXT = gql`
  query {
    getText {
      id
      title
      mode
      sentences
      created_at
      updated_at
    }
  }
`;

export const GET_ALL_TEXT = gql`
  query getAllText($options: GetAllOptions) {
    getAllText(options: $options) {
      data {
        id
        title
        mode
        sentences
        created_at
      }
      pagination {
        total
        page
      }
    }
  }
`;

export const GET_ONE_TEXT = gql`
  query getOneText($id: ID!) {
    getOneText(id: $id) {
      id
      title
      mode
      sentences
      created_at
      updated_at
    }
  }
`;

export const GET_SETTING = gql`
  query {
    getSetting {
      id
      textId
      text {
        id
        mode
        title
        created_at
        updated_at
      }
      mode
    }
  }
`;

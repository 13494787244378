import React from "react";
import { useTypingState } from "../../states/typing-state/useTypingState";

function ResultTable() {
  const { state } = useTypingState();

  const result = state.result;
  return (
    <div className="fixed top-12 border border-black w-1/2 h-32 p-4">
      <div className="grid grid-cols-2">
        <div className="flex items-center">
          <div>
            <span className=" font-bold text-2xl text-green-600 ">WPM: </span>
            <span className="text-2xl font-bold text-green-600">
              {result?.wpm}
            </span>
          </div>
        </div>
        <div>
          <div className="flex justify-between">
            <span className="">Keystrokes</span>
            <span>
              {result?.keystrokes} (
              <span className="text-green-600">
                {result?.correctKeystrokes} |
              </span>
              <span className="text-red-600"> {result?.wrongKeystrokes}</span>)
            </span>
          </div>
          <div className="flex justify-between">
            <span className="">Accuracy</span>
            <span className="font-bold">{result?.accuracy}%</span>
          </div>
          <div className="flex justify-between">
            <span className="">Correct words</span>
            <span className="font-bold text-green-600">
              {result?.correctWords}
            </span>
          </div>
          <div className="flex justify-between">
            <span className="">Wrong words</span>
            <span className="font-bold text-red-600">{result?.wrongWords}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResultTable;

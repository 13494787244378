import { gql } from "@apollo/client";

export const DELETE_ONE_TEXT = gql`
  mutation ($id: ID!) {
    deleteOneText(id: $id) {
      id
    }
  }
`;

export const DELETE_MANY_TEXT = gql`
  mutation ($ids: [ID]) {
    deleteManyText(ids: $ids)
  }
`;

export const CREATE_TEXT = gql`
  mutation ($data: CreateTextInput) {
    createText(data: $data) {
      id
      title
      sentences
    }
  }
`;

export const UPDATE_ONE_TEXT = gql`
  mutation ($id: ID!, $data: UpdateTextInput) {
    updateText(id: $id, data: $data) {
      id
      title
      sentences
      created_at
      updated_at
    }
  }
`;

export const UPDATE_SETTING = gql`
  mutation ($textId: String, $mode: String) {
    updateSetting(data: { textId: $textId, mode: $mode })
  }
`;

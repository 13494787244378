export interface TypingState {
  text: string[];
  timerId?: number;
  input: string;
  pos: number;
  historyInput: any[];
  historyResult: [];
  seconds: number;
  limitTime: number;
  result?: {
    wpm: number;
    accuracy: number;
    keystrokes: number;
    correctKeystrokes: number;
    wrongKeystrokes: number;
    correctWords: number;
    wrongWords: number;
  };
  isDone: boolean;
}

export const initialState: TypingState = {
  text: [],
  timerId: undefined,
  input: "",
  pos: 0,
  historyInput: [],
  seconds: 0,
  limitTime: 60,
  result: undefined,
  historyResult: [],
  isDone: false,
};

export enum ACTIONS {
  ON_INPUT,
  ON_ENTER,
  SET_TIMER,
  TICK,
  ON_COMPLETE_TYPING,
  INIT_TYPING,
}

export interface Action<T> {
  type: ACTIONS;

  payload?: T;
}

type Transducer = (state: TypingState, action: Action<any>) => TypingState;

export const reducer: Transducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.ON_INPUT:
      return { ...state, input: action.payload };
    case ACTIONS.ON_ENTER:
      return {
        ...state,
        pos: action.payload.pos,
        input: action.payload.input,
        historyInput: action.payload.historyInput,
        text: action.payload.text,
        result: action.payload.result,
        historyResult: action.payload.historyResult,
      };
    case ACTIONS.TICK:
      return {
        ...state,
        seconds: state.seconds + 1,
      };
    case ACTIONS.SET_TIMER:
      return {
        ...state,
        timerId: action.payload,
      };
    case ACTIONS.ON_COMPLETE_TYPING:
      return {
        ...state,
        result: action.payload.result,
        isDone: true,
      };
    case ACTIONS.INIT_TYPING:
      return {
        ...state,
        text: action.payload.text,
      };
    default:
      return state;
  }
};

import React, { useState } from "react";
import {
  AiOutlineMenu,
  AiOutlineFileText,
  AiOutlineSetting,
} from "react-icons/ai";
import { TiMessageTyping } from "react-icons/ti";
import Drawer from "./Drawer";
import { Link } from "react-router-dom";

function MenuButton() {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div>
      <div
        onClick={() => setIsOpen(true)}
        className="fixed left-4 bottom-4 bg-black p-2 text-white text-xl cursor-pointer"
      >
        <AiOutlineMenu />
      </div>
      <Drawer isOpen={isOpen} setIsOpen={setIsOpen}>
        <div className="flex flex-col gap-4 mx-12">
          <Link
            to="/"
            className="flex gap-3 items-center text-gray-700 hover:text-gray-900 "
          >
            <TiMessageTyping className="text-3xl" />
            <div className="text-lg">Practice</div>
          </Link>
          <Link
            to="/your-text"
            className="flex gap-3 items-center text-gray-700 hover:text-gray-900"
          >
            <AiOutlineFileText className="text-3xl" />
            <div className="text-lg">Your Text</div>
          </Link>
          <Link
            to="/setting"
            className="flex gap-3 items-center text-gray-700 hover:text-gray-900"
          >
            <AiOutlineSetting className="text-3xl" />
            <div className="text-lg">Setting</div>
          </Link>
        </div>
      </Drawer>
    </div>
  );
}

export default MenuButton;

import React, { useEffect, useState } from "react";
import { MdOutlineCancel } from "react-icons/md";
import cx from "classnames";
import { useMutation, useLazyQuery } from "@apollo/client";
import { UPDATE_ONE_TEXT } from "../../graphql/mutation";
import { useParams } from "react-router-dom";
import { GET_ONE_TEXT } from "../../graphql/query";
import client from "../../graphql";
import get from "lodash/get";
import { toast } from "react-toastify";

function EditYourTextPage() {
  const params = useParams();
  const [updateOneText, { data, loading }] = useMutation(UPDATE_ONE_TEXT);
  const [sentences, setSentences] = useState<string[]>([]);
  const [text, setText] = useState("");
  const [title, setTitle] = useState("");
  const [mode, setMode] = useState("");

  const [textSelected, setTextSelected] = useState<number>(-1);

  const addSentence = (sentence: string) => {
    const arr = [...sentences];
    arr.push(sentence);

    setSentences(arr);
  };

  const editSentence = (index: number, sentence: string) => {
    const arr = [...sentences];
    arr[index] = sentence;

    setSentences(arr);
  };

  const removeSentence = (index: number) => {
    const arr = [...sentences];
    arr.splice(index, 1);
    setSentences(arr);
  };

  const handleUpdate = () => {
    updateOneText({
      variables: { data: { title, sentences, mode }, id: params.id },
    })
      .then((result) => {
        console.log(result);
        toast.success("Updated text successfully!");
      })
      .catch((err) => {
        toast.error("Error. Please try again!");
        throw err;
      });
  };

  useEffect(() => {
    if (params.id) {
      client
        .query({
          query: GET_ONE_TEXT,
          variables: {
            id: params.id,
          },
        })
        .then((result) => {
          const title = get(result, "data.getOneText.title");
          const sentences = get(result, "data.getOneText.sentences") || [];
          const mode = get(result, "data.getOneText.mode");

          setTitle(title);
          setSentences(sentences);
          setMode(mode);
        })
        .catch((err) => {
          console.log(err);
          throw err;
        });
    }
  }, [params]);

  return (
    <div className="w-full h-full flex justify-center py-20">
      <div className="lg:w-1/2 sm:w-3/4">
        <div className="mb-2">
          <div className="mb-2">Title</div>
          <input
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            className="w-full p-2 border border-black"
            placeholder="Title ..."
            type="text"
          />
        </div>

        <div className="mb-2">
          <div className="mb-2">Mode</div>
          <select
            onChange={(e) => {
              setMode(e.target.value);
            }}
            value={mode}
            className="w-full border border-black p-2"
          >
            <option value="random">Random</option>
            <option value="linear">Linear</option>
            <option value="reverse">Reverse</option>
          </select>
        </div>

        <div className="mb-4">
          <div className="mb-2">Sentences</div>
          <div className="border border-black w-full h-96 overflow-y-auto">
            {sentences.map((sentence, index) => {
              return (
                <div key={index} className="relative my-4 mx-8">
                  <div
                    className={cx(
                      "bg-purple-200 border-2 p-2 rounded cursor-pointer",
                      {
                        "border-red-500": textSelected === index,
                        "border-purple-200": textSelected !== index,
                      }
                    )}
                    onClick={() => {
                      if (textSelected === index) {
                        setTextSelected(-1);
                        setText("");
                      } else {
                        setTextSelected(index);
                        setText(sentences[index]);
                      }
                    }}
                  >
                    {sentence}
                  </div>
                  <MdOutlineCancel
                    onClick={(e) => {
                      e.preventDefault();
                      removeSentence(index);
                      setTextSelected(-1);
                    }}
                    className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 text-2xl hover:text-gray-700 cursor-pointer"
                  />
                </div>
              );
            })}
          </div>
        </div>

        <textarea
          value={text}
          onChange={(event) => {
            setText(event.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();

              if (textSelected >= 0) {
                editSentence(textSelected, text);
                setTextSelected(-1);
              } else addSentence(text);
              setText("");
              return;
            }
          }}
          className="w-full border border-black h-32 p-2 mb-4"
        ></textarea>

        <div className="text-center">
          <button
            onClick={handleUpdate}
            className={cx("btn btn-outline btn-sm", { loading: loading })}
          >
            Update
          </button>
        </div>
      </div>
    </div>
  );
}

export default EditYourTextPage;

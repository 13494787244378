import React, { Fragment, useEffect } from "react";
import Timer from "../components/Common/Timer";
import { TypingState } from "../states/typing-state/state";
import { useTypingState } from "../states/typing-state/useTypingState";
import cx from "classnames";
import ResultTable from "../components/Common/ResultTable";
import { useQuery } from "@apollo/client";
import { GET_TEXT } from "../graphql/query";
import LoadingSpinner from "./YourText/LoadingSpinner";
import get from "lodash/get";

function DisplaySentence({
  sentence,
  input,
  index,
  pos,
}: TypingState & { sentence: string; index: number }) {
  // Current Position Text
  if (index === pos) {
    return (
      <div className="" key={index}>
        {sentence.split("").map((char, i) => {
          if (i >= input.length)
            return (
              <Fragment key={i}>
                <span>{char}</span>
              </Fragment>
            );

          if (char === input.charAt(i))
            return (
              <Fragment key={i}>
                <span className="text-green-600">{char}</span>
              </Fragment>
            );
          else {
            if (char === " ") {
              return (
                <Fragment key={i}>
                  <span className="h-full bg-red-300"> </span>
                </Fragment>
              );
            }
            return (
              <Fragment key={i}>
                <span className="text-red-600">{char}</span>
              </Fragment>
            );
          }
        })}
      </div>
    );
  }

  // Default Text
  return (
    <Fragment key={index}>
      <div>{sentence}</div>
    </Fragment>
  );
}

function DisplayText() {
  const { state } = useTypingState();
  const arrText = state.text;

  return (
    <div
      style={{ minHeight: "100px" }}
      className="overflow-auto w-full h-full text-3xl font-light p-4 pb-0"
    >
      <DisplaySentence
        sentence={arrText[state.pos] || ""}
        index={state.pos}
        {...state}
      />
    </div>
  );
}

function HomePage() {
  const { onInput, onEnterPressed, state, initText } = useTypingState();
  const { loading, error, data, refetch } = useQuery(GET_TEXT);

  const onChange = (event: any) => {
    const value = event.target.value;
    onInput(value);
  };

  const onKeyDown = (e: any) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      onEnterPressed();
    }
  };

  useEffect(() => {
    refetch().then((result) => {
      initText(result.data.getText.sentences);
    });
  }, []);

  const checkInput = state.input === state.text[state.pos];

  return (
    <div className="w-full h-screen flex justify-center items-center">
      <div
        style={{ maxHeight: "80vh" }}
        className="lg:w-1/2 sm:w-3/4 flex flex-col border border-black rounded-sm lg:mb-64 sm:mb-32  text-xl"
      >
        {!loading && <DisplayText />}
        {loading && <LoadingSpinner />}
        <div className="w-full mt-4">
          <input
            disabled={state.isDone}
            autoFocus
            value={state.input}
            onChange={onChange}
            onKeyDown={onKeyDown}
            className={cx(
              "w-full outline-none border-t px-4 py-2 text-3xl font-light",
              {
                "border-green-600": checkInput,
                "border-black": !checkInput,
              }
            )}
          ></input>
        </div>
      </div>
      <Timer />
      {state.isDone && <ResultTable />}
    </div>
  );
}

export default HomePage;
